import { useLogEvent } from "../hooks/useLogEvent";

export const WsFloatButton = () => {

    const { handleLogEvent } = useLogEvent()

    return (

        <a
            className='button__floatButton'
            target="_blank"
            href="https://wa.me/+5491171930789?text=Hola Faetani Techos! Me contacto desde la web. Quiero hacer una consulta"
            rel="noreferrer"
            onClick={() => {
                handleLogEvent('Click_boton_whatsapp_flotante');
            }}
        >

            <img
                src={require('../images/whatsapp.webp')}
                alt='Logo WhatsApp'
            />

        </a>

    )
}
